<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>

  <div v-if="participant && contentPage" class="d-flex flex-column align-items-center w-100" id="participant">
    <div class="d-flex flex-column align-items-center w-100">
      <div class="participant-img__cont mb-1" :class="isParticipantSelected? 'selected':''">
        <img :src="participant.transparent_picture.public_path" alt="" class="participant-img">
        <div class="selected-tag" v-if="isParticipantSelected">
          <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-love-white" alt="">
          <span>Es tu favorito</span>
        </div>
      </div>

      <p class="main-p biggest font-weight-bold mb-0">
        {{ participant.nickname }}
      </p>

      <p class="main-p mb-0 mt-1">{{ participant.hashtag }}</p>

      <div class="d-flex">
        <div class="social-participant" v-for="(socialNetwork, index) in orderedSocialNetworks" :key="index">
          <a class="m-3"
             v-if="socialNetwork.url"
             :href="socialNetwork.url"
             target="_blank">
            <img :src="socialNetworkIcon(socialNetwork.type)" alt="" class="social-participant__icon">
          </a>
        </div>
      </div>

      <div class="d-flex flex-column my-3 w-100" style="gap:15px;">
        <button class="main-btn d-flex align-items-center justify-content-center"
                style="gap:5px"
                @click="openModal"
                v-if="!isParticipantSelected && eventStatus === 'active'">
          <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-love-white" alt=""
               style="background-size: contain;object-fit:contain;">
          <span v-if="loading" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
          <span
              v-if="isParticipantSelected">{{ contentPage.instance.fav_selected_button.content.values.text.value }}</span>
          <span v-else>{{ contentPage.instance.fav_select_button.content.values.text.value }}</span>
        </button>
<!--        <button class="secondary-btn simple-btn mb-3 d-flex align-items-center justify-content-center"-->
<!--            @click="$router.push('/video/' + slug)"-->
<!--            style="gap:10px" :disabled="loading">-->
<!--            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-play" alt="">-->
<!--            {{contentPage.instance.presentation_video_button.content.values.text.value}}-->
<!--          </button>-->
        <button class="secondary-btn simple-btn d-flex align-items-center justify-content-center"
                @click="showVideo()"
                style="gap:10px">
          <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-play" alt="">
          {{ contentPage.instance.presentation_video_button.content.values.text.value }}
        </button>
        <div class="participant-iframe__container" v-if="this.showingVideo">
          <iframe loading="lazy"
                  class="mb-3"
                  :src="participant.introductory_video"
                  scrolling="no"
                  frameborder="0" allowfullscreen
                  style="min-height: 500px"
                  ref="participantIframe">
          </iframe>
          <div ref="exitParticipantIframe" @click="exitFullVideo()">
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/btn-close" alt="">
          </div>
        </div>

        <button class="secondary-btn simple-btn d-flex align-items-center justify-content-center" style="gap:5px"
                :disabled="loading" @click="showShareButtons = true">
          <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-share" alt="">
          {{ contentPage.instance.share_button.content.values.text.value }}
        </button>

        <div class="mb-3"></div>
      </div>

      <CaliModal ref="ModalShare" modalSize="sm" v-if="showShareButtons" @close="showShareButtons = false">
        <template v-slot:header>
          <h4 class="modal-title" id="exampleModalLabel">
            {{ contentPage.instance.share_button.content.values.text.value }}</h4>
        </template>

        <template v-slot:body>
          <SharingButtons :participant="participant" @close="showShareButtons = false"></SharingButtons>
        </template>

        <template v-slot:footer>
          <button type="button" class="main-btn simple-btn" @click="showShareButtons = false">Cerrar</button>
        </template>
      </CaliModal>

      <CaliModal ref="ModalParticipant" modalSize="sm" v-if="showConfirmationModal"
                 @close="showConfirmationModal = false">
        <template v-slot:header>
          <h4 class="modal-title" id="exampleModalLabel"></h4>
        </template>

        <template v-slot:body>

          <div class="d-flex flex-column align-items-center w-100">
            <div v-if="isFirstSelection">
              <span
                  v-html="replaceVariables(contentPage.instance.select_favorite_confirmation.content.values.description.value)"></span>
            </div>
            <div v-else>
              <span
                  v-html="replaceVariables(contentPage.instance.change_favorite_confirmation.content.values.description.value)"></span>
            </div>

            <button class="main-btn simple-btn mt-3" @click="setAsFavorite()"
                    :disabled="loading || isParticipantSelected">
              <span v-if="loading" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
              <span> {{ contentPage.instance.confirm_selection_button.content.values.text.value }}</span>
            </button>
          </div>


        </template>

        <template v-slot:footer>
          <span></span>
        </template>
      </CaliModal>
    </div>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>
</template>

<script>
import axios from 'axios';
import SharingButtons from './SharingButtons.vue';
import CaliModal from '@mints-components/cali-modal/App.vue';
import LobbyTabs from './LobbyTabs.vue'
import Loader from './Loader.vue';
import orderedSocialNetworks from "./Mixins/orderedSocialNetworks";

export default {
  // inject: ['participant'],
  data() {
    return {
      contentPage: null,
      loaded: false,
      loading: false,
      showShareButtons: false,
      showConfirmationModal: false,
      favoriteParticipantId: null,
      showingVideo: false,
      me: Object,
      participant: Object,
    };
  },
  mixins: [orderedSocialNetworks],
  props: {
    // me: Object,
    eventStatus: String
  },
  components: {SharingButtons, CaliModal, LobbyTabs, Loader},
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    isParticipantSelected() {
      if (this.participant.story_id == this.favoriteParticipantId) {
        return true;
      }
      return false;
    },
    isFirstSelection() {
      return (this.favoriteParticipantId == null)
    },

  },
  mounted() {
    this.getSupportData();
  },
  created() {
    this.getMe()
    this.getParticipant()
  },
  methods: {
    getParticipant() {
      axios.get('api/v1/get-selected-participant/' + this.slug).then(response => {
        if (response.data) {
          this.participant = response.data
        }
      }).catch(error => {
        if (error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/')
            }
          })
        }
      });
    },
    getMe() {
      var token = localStorage.getItem('mints_contact_id');

      if (token != null) {
        let localStorageKey = 'followers.contact.' + token
        this.me = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
      }
    },
    showVideo() {
      this.showingVideo = true;
      if (this.showingVideo == true) {
        this.$nextTick(() => {
          this.$refs.participantIframe.classList.add('full-screen__video');
          this.$refs.exitParticipantIframe.classList.add('full-screen__exit')
        })
      }
    },
    exitFullVideo() {
      if (this.$refs.participantIframe.classList.contains('full-screen__video') == true) {
        this.showingVideo = false;
        this.$refs.participantIframe.classList.remove('full-screen__video');
        this.$refs.exitParticipantIframe.classList.remove('full-screen__exit')
      }
    },
    getSupportData() {
      if (this.me && this.me.id) {
        axios.get('api/v1/get-selected-favorite').then(response => {
          if (response.data) {
            this.favoriteParticipantId = response.data.story_id;
          }
        }).catch(error => {
          if (error.response.status == 440) {
            this.$swal.fire({
              title: 'La sesión ha expirado',
              confirmButtonText: 'Regresar al inicio',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = '/';
              }
            })
          }
        });
      }
      axios.get("api/v1/get-content-page/participant").then(response => {
        this.contentPage = response.data.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    setAsFavorite() {
      this.loading = true;
      axios.post("api/v1/set-influencer-as-favorite", {
        storyId: this.participant.story_id,
        storySlug: this.participant.slug,
        title: this.participant.nickname
      }).then(response => {
        this.favoriteParticipantId = response.data;
      }).catch(error => {
        if (error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      }).finally(() => {
        this.loading = false;
        this.showConfirmationModal = false;
      });
    },
    replaceVariables(text) {
      text = text.replace(/\{\{influencer\}\}/g, this.participant.nickname);
      text = text.replace(/\{\{open-span\}\}/g, '<span class="font-weight-bold main-color-text">');
      text = text.replace(/\{\{close-span\}\}/g, '</span>');
      return text;
    },

    openModal() {
      if (this.me) {
        this.showConfirmationModal = true
      } else {
        this.$cookies.set('redirect_url', window.location.pathname)
        this.$router.push('/')
      }
    },

    setTimes(diff) {
      const days = parseInt(diff / 86400);
      const hours = parseInt(diff / 3600) - (days * 24);
      const minutes = parseInt(diff / 60) - ((days * 1440) + (hours * 60));
      const seconds = parseInt(diff) - ((days * 86400) + (hours * 3600) + (minutes * 60));
      const milliseconds = (diff - parseInt(diff)) * 1000;

      return {
        days,
        milliseconds,
        seconds,
        minutes,
        hours,
      }
    },

    socialNetworkIcon(type) {
      let slug = null;
      switch (type) {
        case 'Twitter':
          slug = 'twitter-icon'
          break;
        case 'Instagram':
          slug = 'instagram-icon'
          break;
        case 'Facebook':
          slug = 'face-icon'
          break;

      }
      return 'https://endemolcxfprod-16905.kxcdn.com/public-assets/' + slug
    },
  },

};
</script>

<style lang="scss">
.main-color-text {
  color: var(--main-color);
}

.full-screen__video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000;
  color: #fff;
}

.full-screen__exit {
  position: fixed;
  top: 85%;
  left: 50%;
  z-index: 99999;
  color: #fff;
  display: flex;
  cursor: pointer;

  img {
    opacity: .8;
    transition: opacity .5s ease-out;
    width: 45px;
    height: 45px;
    transform: translate(-50%);

    &:hover {
      opacity: 1;
    }
  }
}
</style>
