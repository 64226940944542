import App from '@views/home/Layout.vue';
import {generateApplication} from '@helpers/generate_component.js';
import {generateRouter} from "@routers/application.js";
import {createPinia} from "pinia"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import SocialSharing from 'vue-social-sharing';
import {createApp} from 'vue';
import VueCookies from 'vue-cookies'

const app = createApp(App);
// const head = createHead()
app.component('social-sharing', SocialSharing);
app.use(VueCookies, {expires: '1d'})
// app.use(head)


// import App from '@views/settings/index/layout/Index.vue'
// import { generateApplication } from '@helpers/generate_component.js';
// import { generateRouter } from "@routers/settings.js";
import store from '@stores/settings.js';
import {generateAxiosInstance} from '@helpers/axios_provider.js'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import {autoAnimatePlugin} from "@formkit/auto-animate/vue"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const router = generateRouter(['all'])

const injections = [{
    name: '$axios',
    module: generateAxiosInstance('/api/v1/user')
}]

const components = [{
    name: 'VueCtkDateTimePicker',
    module: VueCtkDateTimePicker
}]

let props = {}

if (typeof participant !== 'undefined') {
    props = {participant}
}

if (typeof mints_contact_token !== 'undefined') {
    props = {...props, mints_contact_token}
}

if (typeof id_token !== 'undefined') {
    props = {...props, id_token}
}

if (typeof event_status !== 'undefined') {
    props = {...props, event_status}
}

if (typeof is_pre_register !== 'undefined') {
    props = {...props, is_pre_register}
}

if (typeof app_env !== 'undefined') {
    props = {...props, app_env}
}

// generateApplication(App, "#app", injections, { me: me }, [store, router, createPinia, VueSweetalert2, autoAnimatePlugin], components)

generateApplication(
    App,
    "#app",
    {
        injections: [{name: '$axios', module: generateAxiosInstance('/')}],
        packages: [router, store, createPinia(), VueSweetalert2, autoAnimatePlugin, VueCookies],
        components,
        props
    }
)