<template>
  <div class="d-flex justify-content-center" style="margin-top: 5rem; margin-bottom: 5rem">
    <RouterView v-slot="{ Component }">
      <!--             <PWAInstallation/>-->
      <!--             v-if="!!me && showLobbyTabs"-->
      <component v-if="loaded" :is="Component" :key="$route.path"
                 :me="me" class="w-100"
                 @clearSessionVars="clearSessionVars"
                 :token="mintsContactId"
                 :appEnv="app_env"
                 :eventStatus="event_status"
                 :idToken="id_token"
                 :mintsContactToken="mints_contact_token"
                 @updateMe="updateMe"
      />
      <div v-else class="loader">
        <Loader></Loader>
      </div>
    </RouterView>
    <LobbyTabs v-if="showLobbyTabs" :eventStatus="event_status" :selectedTab="selectedTab"
               :isReturn="isReturn"
               :isPreRegister="is_pre_register"
    />
  </div>
</template>

<script>
import ONE_SIGNAL_SDK from "../../../../public/OneSignal.js";
import {mapMutations, mapState} from "vuex";
import axios from 'axios';
import additional_data_check from "./Mixins/additional_data_check";
import Loader from "./Loader.vue";
import LobbyTabs from "./LobbyTabs.vue";
import {computed} from "vue";
// import * as PusherPushNotifications from "@pusher/push-notifications-web";
import PWAInstallation from "./PWAInstallation.vue";

export default {
  name: "App",
  inject: ['$axios'],
  components: {
    Loader, LobbyTabs, PWAInstallation
  },
  mixins: [additional_data_check],
  props: {
    participant: Object,
    mints_contact_token: String,
    id_token: String,
    event_status: String,
    is_pre_register: Boolean,
    app_env: String,
  },
  provide() {
    return {
      participant: computed(() => this.participant || {}),
      mintsContactToken: computed(() => this.mints_contact_token || null),
      idToken: computed(() => this.id_token || null),
      eventStatus: computed(() =>this.event_status || null),
      isPreRegister: computed(() =>this.is_pre_register || null),
      appEnv: computed(() =>this.app_env || null)
    }
  },
  data: () => ({
    appId: APP_ID,
    safariWebId: SAFARI_WEB_ID,
    oneSignal: ONE_SIGNAL_SDK,
    signalCategories: [{
      tag: "Este",
      label: "Este"
    }, {
      tag: 'Central',
      label: 'Central'
    }, {
      tag: "Montana",
      label: "Montaña"
    },
      {
        tag: 'Pacifico',
        label: 'Pacífico'
      }, {
        tag: 'Alaska',
        label: 'Alaska'
      }, {
        tag: 'Hawaii',
        label: 'Islas Hawaii'
      }],
    loaded: false,
    sessionLoaded: false,
    viewDataLoaded: false,
    me: null,
    // mintsContactId: null,
  }),
  computed: {
    mintsContactId() {
      return localStorage.getItem('mints_contact_id') || null
    },
    currentPath() {
      return this.$route?.path
    },
    viewName() {
      return this.$route?.name
    },
    requireSession() {
      return this.$route?.meta.requireSession
    },
    redirectNeeded() {
      return this.$route?.meta.redirectNeeded;
    },
    selectedTab() {
      switch(this.$route?.name) {
        case 'Lobby':
        case 'Participant':
          return 'participants';
        case 'Mechanics':
        case 'Poll':
        case 'Quiz':
          return 'interactions';
        case 'Profile':
          return 'profile'
      }
      return null
    },
    showLobbyTabs(){
      if (['Lobby', 'Participant', 'Video', 'Mechanics', 'Poll', 'Quiz', 'Profile'].includes(this.$route.name)){
        return true;
      }
      return false;
    },
    isReturn(){
      if (['Participant', 'Poll', 'Quiz'].includes(this.$route.name)){
        return true;
      }
      return false;
    },
  },
  created() {
    let device = this.getDevice();
    this.updateDevice(device?.toLowerCase());

    window.onappinstalled = () => {
      this.updateAppStatus('Instalado');
    };

    window.addEventListener("beforeinstallprompt", (event) => {
      this.checkUninstalledApp(event);
    });
  },
  async mounted() {
    await this.checkForSession();
    await this.configNotifications();

    if (window.matchMedia('(display-mode: standalone)').matches) {
      if (this.me && !this.me?.app_status?.includes('Instalado')) {
        this.updateAppStatus('Instalado');
      }
    }
  },
  watch: {
    currentPath() {
      this.resetObjects();
    },
    viewName(newValue) {
      this.viewDataLoaded = true;
      this.checkForSession();
      this.checkForDataNeeded();
    },
    '$route': function () {
      if (this.$route?.name === 'Participant' && this.participant && this.participant.nickname) {
        var title = 'Los 50 - ' + this.participant.nickname;
      } else {
        var title = this.$route?.meta?.title || 'Los 50'
      }
      this.observeCaptcha();
      document.title = title
    }
  },
  methods: {
    ...mapMutations(["resetObjects"]),

    observeCaptcha() {
      const observer = new MutationObserver((mutations, observer) => {
        let captchaElement = document.querySelector('.grecaptcha-badge');
        if (captchaElement) {
          this.hideCaptcha();
          // observer.disconnect();
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    },

    configNotifications() {
      const signalClient = this.oneSignal;
      const timeZone = this.getTimeZone();
      const category = this.signalCategories.find((category) => category.tag === timeZone);
      signalClient.start({
        appId: this.appId,
        safari_web_id: this.safariWebId,
        categories: [category],
        defaultValue: [category],
        bellButton: false
      });
    },

    hideCaptcha(){
      let captchaElement = document.querySelector('.grecaptcha-badge')
      let path = this.$route.path
      if (path === '/' || path === '/registro' || path === '/login' || path === '/datos-adicionales') {
        if(captchaElement !== null){
          captchaElement.style.setProperty('visibility', 'visible');
        }
      } else {
        if(captchaElement !== null){
          captchaElement.style.setProperty('visibility', 'hidden');
        }
      }
    },

    getTimeZone() {
      return this.me ? this.me.time_zone.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_") : 'Central';
    },


    updateMe(me) {
      this.me = me;
      this.checkAppStatus();
    },
    checkForDataNeeded() {
      if (this.sessionLoaded && this.viewDataLoaded) {
        this.checkForRedirect();
      }
    },
    clearSessionVars() {
      let token = localStorage.getItem('mints_contact_id')
      window.localStorage.removeItem('followers.contact.' + token);
      this.me = null;
      this.sessionLoaded = false;
      this.tokenFlag = null;
      this.viewDataLoaded = false;
      localStorage.setItem('isOut', 'true')
    },
    checkForSession() {
      let token = localStorage.getItem('mints_contact_id')
      if (token != null) {
        let localStorageKey = 'followers.contact.' + token;
        this.me = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
        if (this.me == null) {

          axios.post('api/v1/get-session', {id: token}).then(response => {
            if (response.data) {
              let contactData = response.data;
              this.updateLocalStorage(localStorageKey, contactData);
              this.checkAppStatus();
            }
          });
        } else {
          this.checkAppStatus();
        }
      }
      this.sessionLoaded = true;
      this.checkForDataNeeded();
    },
    async checkForRedirect() {
      if (this.requireSession) {
        if (this.me != null) {
          this.checkForData();
        } else {
          this.clearSessionVars()
          this.$cookies.set('redirect_url', window.location.pathname)
          this.$router.push('/')
        }
      } else {

        if (this.me != null) {
          this.checkForData();
          if (this.redirectNeeded) {
            this.$router.push('/lobby')
          }
        } else {

        }
      }
      this.loaded = true;
    },

    checkAppStatus() {
      if (this.me && !this.me.app_status) {
        let status = 'No instalado';
        const mqStandAlone = '(display-mode: standalone)';
        if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
          status = 'Instalado';
        }
        this.updateAppStatus(status);
      }
    },

    updateLocalStorage(localStorageKey, contact) {
      let me = {
        'id': contact.id,
        'mints_contact_id': contact.token_id,
        'given_name': contact.given_name,
        'last_name': contact.last_name,
        'phone': contact.phone || null,
        'email': contact.email || null,
        'zip_code': contact.zip_code || null,
        'time_zone': contact.time_zone || null,
        'confirm_age': contact.confirm_age || null,
        'privacy_policy': contact.privacy_policy || null,
        'terms_and_conditions_accepted': contact.terms_and_conditions_accepted || null,
        'send_promotions_and_news': contact.send_promotions_and_news || null,
        'full_name': contact.full_name,
        'display_name': contact.display_name,
        'app_status': contact.app_status,
        'device': contact.device,
      }
      window.localStorage.setItem(localStorageKey, JSON.stringify(me));
      this.me = me;
    },

    checkUninstalledApp(event, intent = 1) {
      if (this.me) {
        if (this.me.app_status?.includes('Instalado')) {
          this.updateAppStatus('Desinstalado');
        }
      } else if (intent <= 10) {
        setTimeout(() => {
          this.checkUninstalledApp(event, intent + 1)
        }, 500)
      }
    },

    updateAppStatus(status) {
      let token = localStorage.getItem('mints_contact_id')
      this.$axios.post(`/api/v1/app-installed`, {status}).then(r => {
        if (r.data.status !== 422) {
          let localStorageKey = 'followers.contact.' + token;
          this.updateLocalStorage(localStorageKey, {...this.me, app_status: status});
        }
      });
    },

    getDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad Simulator|iPhone Simulator|iPod Simulator|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/Mac OS|mac os|mac OS/.test(userAgent) && !window.MSStream) {
        return "Mac OS";
      }

      return 'Web'
    },

    updateDevice(device, intent = 1) {
      if (this.me) {
        let isDifferentDevice = this.me.device?.toLowerCase() !== device;

        if (isDifferentDevice) {
          this.$axios.post(`/api/v1/device`, {device}).then(r => {
            if (r.data.status !== 422) {
              let localStorageKey = 'followers.contact.' + this.mintsContactId;
              this.updateLocalStorage(localStorageKey, {...this.me, device});
            }
          });
        }
      } else if (intent <= 10) {
        setTimeout(() => {
          this.updateDevice(device, intent + 1)
        }, 500)
      }
    },
  }
};
</script>

<style lang="css">
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.tagging-container {
  display: none !important;
}

.slidedown-body-icon img {
  width: 100% !important;
  height: 45px !important;
}
</style>