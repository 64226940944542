<template>
    <div v-if="participant" class="d-flex flex-column align-items-center w-100" id="participant">
      <div class="d-flex flex-column align-items-center w-100">

        <span class="main-p smallest text-left font-weight-bold w-100 mt-0 mb-2">
            <RouterLink :to="'/participante/' + slug" class="d-flex flex-nowrap mb-3 mt-0 back-div" style="gap:10px">
                <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon">
                <div class="back-div">Regresar</div>
            </RouterLink>  
        </span>
        
        <div class="participant-iframe__container" v-if="participant.introductory_video">
          <iframe loading="lazy"
            class="mb-3 participant-iframe"
            scrolling="no" 
            :src="participant.introductory_video"
            frameborder="0" allowfullscreen>
          </iframe>
        <!-- src="https://player.vimeo.com/video/841922756"  -->

        </div>
        <div v-else class="align-items-center">
            <p class="main-p">Este Jugador no tiene video</p>
        </div>
        

        <!-- <p class="main-p biggest font-weight-bold mb-1">
          {{ participant.nickname }}
        </p>
        
        <p class="main-p mt-1 mb-1">{{ participant.hashtag }}</p> -->

        <!-- <div class="social-participant d-flex align-items-center mb-5">
          <div class="mb-5 mt-3" v-for="(socialNetwork, index) in orderedSocialNetworks" :key="index">
            <a  
                  v-if="socialNetwork.url"  
                  :href="socialNetwork.url"
                  target="_blank">
              <img :src="socialNetworkIcon(socialNetwork.type)" alt="" class="social-participant__icon">
            </a>
          </div>
        </div> -->

        
      </div>
    </div>
    <div v-else class="loader">
      <Loader></Loader>
    </div>
</template>

<script>
import SharingButtons from './SharingButtons.vue';
import CaliModal from '@mints-components/cali-modal/App.vue';
import LobbyTabs from './LobbyTabs.vue'
import Loader from './Loader.vue';
import orderedSocialNetworks from "./Mixins/orderedSocialNetworks";

export default {
    inject: ['participant'],
    data() {
        return {
            loaded: false,
            loading: false,
        };
    },
    props: {
      me: Object
    },
    mixins: [orderedSocialNetworks],
    components: { SharingButtons, CaliModal, LobbyTabs, Loader },
    computed: {
        slug() {
            return this.$route.params.slug;
        },
    },
    mounted() {
    },
    methods: {
        socialNetworkIcon(type) {
            let slug = null;
            switch (type) {
                case 'Twitter':
                    slug = 'twitter-icon'
                    break;
                case 'Instagram':
                    slug = 'instagram-icon'
                    break;
                case 'Facebook':
                    slug = 'face-icon'
                    break;
                        
            }
            return 'https://endemolcxfprod-16905.kxcdn.com/public-assets/' + slug
        }
    },
};
</script>

<style lang="scss">
  .main-color-text {
    color: var(--main-color);
  }
  .participant-iframe__container{
    padding: 0 30px;
    width: 100%;
    max-width: 400px;
    .participant-iframe{
      width: 100%;
      aspect-ratio: 9/16;
    }
  }
</style>
