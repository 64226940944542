import { createRouter, createWebHistory } from "vue-router";
import Login from "@views/home/Login.vue";
import Register from "@views/home/Register.vue";
import Profile from "@views/home/Profile.vue";
import Home from "@views/home/Home.vue";
import Lobby from "@views/home/Lobby.vue"
import Participant from "@views/home/Participant.vue"
import Mechanics from "@views/home/Mechanics.vue"
import Quiz from "@views/home/Quiz.vue"
import Poll from "@views/home/Poll.vue"
import AdditionalFieldsForm from "@views/home/AdditionalFieldsForm.vue"
import ExtraAdditionalFieldsForm from "@views/home/ExtraAdditionalFieldsForm.vue"
import ParticipantVideo from "@views/home/ParticipantVideo.vue"

export function generateRouter(permissions) {
  let routesFiltered = null
  
    routesFiltered = [{
      path: '/',
      name: "Home",
      component: Home,
      permission: 'all',
      meta: {
        title: 'Los 50',
        requireSession: false,
        redirectNeeded: true
      }
    },{
      path: '/lobby',
      name: "Lobby",
      component: Lobby,
      permission: 'all',
      meta: {
        title: 'Los 50 - Jugadores',
        requireSession: true,
        redirectNeeded: true
      }
    },{
      path: '/login',
      name: "Login",
      component: Login,
      permission: 'all',
      meta: {
        title: 'Los 50 - Login',
        requireSession: false,
        redirectNeeded: true
      }
    }, {
      path: '/registro',
      name: "Register",
      component: Register,
      permission: 'all',
      meta: {
        title: 'Los 50 - Registro',
        requireSession: false,
        redirectNeeded: true
      }
    }, {
      path: '/datos-adicionales',
      name: "Additional",
      component: AdditionalFieldsForm,
      permission: 'all',
      meta: {
        title: 'Los 50',
        requireSession: true,
        redirectNeeded: true
      }
    }, {
      path: '/datos-extra-adicionales',
      name: "Extra Additional",
      component: ExtraAdditionalFieldsForm,
      permission: 'all',
      meta: {
        title: 'Los 50',
        requireSession: true,
        redirectNeeded: true
      }
    }, {
      path: '/perfil',
      name: "Profile",
      component: Profile,
      permission: 'all',
      meta: {
        title: 'Los 50 - Perfil',
        requireSession: true,
        redirectNeeded: true
      }
    }, {
      path: '/participante/:slug',
      name: "Participant",
      component: Participant,
      permission: 'all',
      meta: {
        title: 'Los 50 - Jugador',
        requireSession: false,
        redirectNeeded: false
      }
    },{
      path: '/video/:slug',
      name: "Video",
      component: ParticipantVideo,
      permission: 'all',
      meta: {
        title: 'Los 50 - Video',
        requireSession: false,
        redirectNeeded: false
      }
    }, {
      path: '/interacciones',
      name: "Mechanics",
      component: Mechanics,
      meta: {
        title: 'Los 50 - Interacciones',
        requireSession: true,
        redirectNeeded: true
      }
    }, {
      path: '/trivia/:slug',
      name: "Quiz",
      component: Quiz,
      permission: 'all',
      meta: {
        title: 'Los 50 - Trivia',
        requireSession: true,
        redirectNeeded: true
      }
    }, {
      path: '/encuesta/:slug',
      name: "Poll",
      component: Poll,
      permission: 'all',
      meta: {
        title: 'Los 50 - Encuesta',
        requireSession: true,
        redirectNeeded: true
      }
    }];
  
  
  return createRouter({
    history: createWebHistory(),
    routes: routesFiltered,
    linkActiveClass: "active",
  });
}
