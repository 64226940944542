<template>
    <div v-if="contentPage" class="d-flex flex-column align-items-center w-100 mt-2" id="register">
        <div class="d-flex flex-column align-items-center w-100">
            
            <p class="main-p bigger mb-0 mt-0 font-weight-bold" style="max-width: 370px" v-html="contentPage.instance.header.content.values.title.value"></p>

            <div class="w-100">
                <div v-if="!formSent" class="w-100">
                    <DynamicForm
                        v-if="registerForm"
                        :data="registerForm"
                        @onSubmit="onSubmit"
                        @onCancel="onCancel"
                        class="w-100 d-flex align-items-center flex-column"
                        />
                </div>
                <div v-else>
                    <div v-if="mode == 'otp' && answers" class="d-flex flex-column align-items-center w-100">
                        <OptLogin :setEmail="answers.email" :setMode="'code'"></OptLogin>
                        <div class="d-flex text-white mt-3">
                            <a href="#" class="text-decoration-underline mx-2 a-link mb-3" @click.prevent="mode = 'magic-link'">Usar acceso seguro</a>
                        </div>
                    </div>
                    <div v-if="mode == 'magic-link'" class="d-flex flex-column align-items-center w-100">
                        <p class="main-p text-center mb-5 px-3" v-html="contentPage.instance.confirmation_header.content.values.title.value"></p>
                        <button class="main-btn my-3"
                            :class="{'disabled-btn': disabledResendBtn, 'main-btn': !disabledResendBtn}"
                            @click="resendEmail"
                            :disabled="disabledResendBtn">
                            {{ contentPage.instance.resend_email_button.content.values.text.value }}
                        </button>
                        <p v-if="disabledResendBtn" class="main-p smallest text-center">
                            Podrás reenviar el correo en {{ countdown }} segundos.
                        </p>
                        <div class="d-flex text-white mt-3">
                            <a href="#" class="text-decoration-underline mx-2 a-link mb-3" @click.prevent="mode = 'otp'">Usar código temporal</a>
                        </div>
                    </div>
                </div>


            </div>
            <div class="d-flex text-white mt-3">
                ¿Ya tienes una cuenta? <RouterLink class="text-decoration-underline mx-2 a-link" to="/login">inicia sesión</RouterLink>
            </div>

            <div class="my-4"></div>

            <div id="oauth-components" class="w-100"></div>
        </div>
    </div>
    <div v-else class="loader">
      <Loader></Loader>
    </div>
</template>

<script>
import DynamicForm from "@mints-components/cali-dynamic-form/App.vue";
import Oauth from '@helpers/oauth.js';
import Loader from "./Loader.vue";
import OptLogin from '@mints-components/otp-login/App.vue';

import axios from 'axios';
export default {
  mixins: [Oauth],

  provide() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZoneName = "Montaña";

    if (timeZone === "America/New_York") {
        timeZoneName = "Este";
    } else if (timeZone === "America/Chicago") {
        timeZoneName = "Central";
    } else if (timeZone === "America/Denver") {
        timeZoneName = "Montaña";
    } else if (timeZone === "America/Los_Angeles") {
        timeZoneName = "Pacífico";
    } else if (timeZone === "America/Anchorage") {
        timeZoneName = "Alaska";
    } else if (timeZone === "Pacific/Honolulu") {
        timeZoneName = "Islas Hawaii";
    } else if (timeZone === "America/Puerto_Rico") {
        timeZoneName = "Atlántico";
    } 
    return {
      $contact: {
        time_zone_2024: timeZoneName
      }
    }
  },

  data() {
    return {
        registerForm: null,
        formSent: false,
        answers: null,
        loaded: false,
        contentPage: null,
        disabledResendBtn: false,
        countdown: 0,
        mode: 'otp'
    }
  },

  props: {
    eventStatus: String
  },

  components: { DynamicForm, Loader, OptLogin },

  mounted: function() {
    if(this.eventStatus != 'active') {
        this.$router.push('/');
    }
    this.removeCookie();
    this.getSupportData();
  },

  methods: {
    removeCookie() {
        axios.get('api/v1/remove-cookie');
    },
    getSupportData() {
        axios.get('api/v1/get-content-page/register').then(response => {
            this.contentPage = response.data.data
            this.registerForm = response.data.data.form.register_form.content
        }).catch((error) => {
            console.log(error);
        })
    },
      onSubmit(answers, response) {
        if (response.error){
          if(response.status === 500){
            this.sendAlert('error', 'Error en el servidor', 'Ocurrió un problema con el registro, inténtalo de nuevo más tarde.')
          }
          if(response.status === 422){
            this.sendAlert('error', 'Formato incorrecto', 'Revisa que la información sea correcta. El campo de Código Postal solo puede contener números y guiones (79930-2345 o 79930), y debe tener una longitud mínima de 3 caracteres.')
          }
          if(response.status === 400){
            this.sendAlert('error', 'Correo ya registrado', 'Este correo ya se encuentra registrado, inicia sesión para acceder a la app.')
          }
        } else {
            this.sendAlert('success', 'Correo enviado.', `Se ha enviado un acceso seguro al correo ${answers.email}`)
            this.formSent = true;
            this.answers = answers;
        }
    },

    onCancel() {
       console.log("Submission cancelled")
    },
    resendEmail() {
        this.disableButton()
        axios.post('api/v1/login/send-magic-link', {data: {email: this.answers.email}}).then(response => {
            let title = 'Correo enviado.'
            let text = `Se ha enviado un acceso seguro al correo ${this.answers.email}`
            this.sendAlert('success', title, text);
        })
    },
    disableButton() {
        this.disabledResendBtn = true;
        this.countdown = 60;
        for (let i = 0; i < 60; i++) {
            setTimeout(() => {
                this.countdown--;
            }, i * 1000);
        }
       
        setTimeout(() => {
            this.disabledResendBtn = false;
        }, 60000);
    },
    sendAlert(icon, title, text) {
        this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        })
      }
  }
};
</script>
