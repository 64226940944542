<template>
        <div v-if="contentPage" class="d-flex flex-column align-items-center w-100" id="register">

            <div v-if="me" class="d-flex flex-column align-items-center w-100 mb-5">
                <p class="main-p bigger mb-2 mt-4 font-weight-bold d-flex justify-content-center" style="gap:10px">
                    <span v-html="contentPage.instance.welcome_header.content.values.title.value"></span>
                    {{ capitalize(me.display_name) }}
                </p>
                <div v-if="favoriteInfluencer" class="profile-card__container py-3">
                    
                    <p class="main-p mb-3 mt-2 font-weight-bold d-flex justify-content-center" v-if="favoriteInfluencer.eliminated && favoriteInfluencer.eliminated === true">
                        ¡Tu jugador favorito ha sido eliminado!
                    </p>
                    <p v-else class="main-p bigger mb-3 mt-0 font-weight-bold d-flex justify-content-center" style="gap:10px"
                        v-html="replaceVariables(contentPage.instance.favorite_influencer_header.content.values.title.value)">
                    </p>
                    <div class="d-flex justify-content-center ">
                        <img class="fan-picture participant-img__cont mb-3" :src="favoriteInfluencer.transparent_picture.public_path" width="150">
                    </div>
                    <p class="main-p biggest font-weight-bold my-0">
                        <span>{{ favoriteInfluencer.nickname }}</span>
                    </p>
                    
                     <p class="main-p mb-2 mt-0">{{ favoriteInfluencer.hashtag }}</p>

                      <div class="d-flex justify-content-center mb-3">
                        <div class="social-participant" v-for="(socialNetwork, index) in orderedSocialNetworks" :key="index">
                          <a class="m-3"
                             v-if="socialNetwork.url"
                             :href="socialNetwork.url"
                             target="_blank">
                            <img :src="socialNetworkIcon(socialNetwork.type)" alt="" class="social-participant__icon">
                          </a>
                        </div>
                      </div>

                    <button v-if="eventStatus === 'active'" class="small-btn mb-3" @click="$router.push('/lobby')">
                        {{ contentPage.instance.change_favorite_influencer_button.content.values.text.value }}
                    </button>
                    <p v-else-if="eventStatus == 'registration_end'" class="main-p smaller justify-content-center" style="gap:10px"
                        v-html="replaceVariables(contentPage.instance.registration_end_selected_fav.content.values.title.value)">
                    </p>
                    <p v-else-if="eventStatus == 'finished'" class="main-p smaller justify-content-center" style="gap:10px"
                        v-html="contentPage.instance.finished_fav_header.content.values.title.value">
                    </p>
                    
                </div>
                <div v-else-if="eventStatus === 'active'">
                    <p class="main-p  mb-3 mt-5  d-flex justify-content-center" style="gap:10px"
                        v-html="contentPage.instance.no_favorite_selected_header.content.values.title.value">
                    </p>
                    <button class="main-btn mb-4" @click="$router.push('/lobby')">
                        {{ contentPage.instance.no_select_favorite_influencer_button.content.values.text.value }}
                    </button>
                </div>

                <div class="w-100">
                    <p class="main-p bigger mb-3 mt-5 font-weight-bold  justify-content-center" style="gap:10px">Encuestas</p>
                        <div class="accordion m-0" id="accordionEncuestas" >
                            <div class="accordion-item mb-1" style="border-radius: 0" v-for="(poll,index) in pollResults">
                                <h2 class="accordion-header">
                                    <button class="accordion-button profile-accordion collapsed font-weight-bold" type="button" data-bs-toggle="collapse"
                                        :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                                       {{poll.poll_title}}
                                    </button>
                                </h2>
                                <div :id="'collapse' + index" class="accordion-collapse collapse" data-bs-parent="#accordionEncuestas">
                                    <div class="accordion-body">
                                        <div v-for="(field, fieldSlug) in poll.results" :key="fieldSlug" class="d-flex flex-column">
                                            <span class="text-white main-p font-weight-bold mb-3 mt-0 ms-0" style="text-align: left">{{ field.title }}</span>
                                            <div v-for="(answer, answerKey) in field.answers" :key="answerKey" class="poll-result mb-3"
                                                 :class="{'active': isActiveAnswer(poll.poll_id, fieldSlug, answerKey)}">
                                                <div class="d-flex align-items-center justify-content-between mb-2" style="gap:10px">
                                                    <span v-html="answerKey"></span>
                                                    <div class="percent text-nowrap">
                                                        {{ calculatePercent(field.answers, answer)}} %
                                                    </div>
                                                </div>
                                                <div class="progress-bar">
                                                <div class="progress" :style="'--percent:' + calculatePercent(field.answers, answer) +'%'"></div>
                                                </div>
                                            </div>
                                            <p class="main-p">
                                                <span class="bigger">Total de votos emitidos: {{ calculateTotal(field.answers) }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div v-if="pollResults.length == 0">
                            <span class="main-p" v-html="contentPage.instance.no_poll_answered_header.content.values.title.value"></span>
                        </div>
                        <p class="main-p bigger mb-3 mt-5 font-weight-bold  justify-content-center" style="gap:10px">Trivias</p>
                        <div class="accordion m-0" id="accordionQuiz">
                            <div class="accordion-item mb-1" style="border-radius: 0" v-for="(quiz,index) in quizResults">
                                <h2 class="accordion-header">
                                    <button class="accordion-button profile-accordion collapsed font-weight-bold" type="button" data-bs-toggle="collapse"
                                        :data-bs-target="'#collapse-quiz' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                                       {{quiz.quiz_title}}
                                    </button>
                                </h2>
                                <div :id="'collapse-quiz' + index" class="accordion-collapse collapse" data-bs-parent="#accordionQuiz">
                                    <div class="accordion-body">
                                        <div v-for="question in quiz.results" class="trivia-question">
                                            <div class="d-flex">
                                                <img v-if="question.answered.is_correct" src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-respuesta-correcta" width="26" height="26" style="margin-top: 0px;">
                                                <img v-else src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-respuesta-incorrecta" width="26" height="26" style="margin-top: 0px;">
                                                
                                                <p class="main-p ms-4 my-1" style="line-height:18px;font-size: 15px;font-weight: 500;text-align: left;" v-html="question.title"></p>
                                            </div>
                                            <div class="ms-3 d-flex" v-if="question.answered && question.answered.title && question.answered.is_correct">
                                                <p class="mb-0"><span class="ms-2 mt-0" style="color:#67eaae;line-height:18px;font-size: 14px;text-align:left">{{ question.answered.title }}</span></p>
                                            </div>
                                            <div class="ms-3 d-flex flex-column align-items-start" v-else-if="question.answered && question.answered.title ">
                                                <p class="mb-0"><span class="ms-2 mt-0" style="color:#ff0008;line-height:18px;font-size: 14px;text-align:left;font-weight: 600;">{{ question.answered.title }}</span></p>
                                                <p class="mb-0" v-for="option in question.options"><span class="ms-2 mt-0" style="color:#67eaae;line-height:18px;font-size: 14px;text-align:left" v-if="option.is_correct == true">{{ option.title }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-if="quizResults.length == 0" class="d-flex justify-content-center">
                            <span class="main-p" >Aún no has completado ninguna trivia</span>
                        </div>


                    <p v-if="eventStatus != 'finished'" class="main-p bigger mb-3 mt-5 font-weight-bold d-flex justify-content-center" style="gap:10px">
                        Instrucciones
                    </p>

                    <p v-if="eventStatus == 'active'" class="main-p mb-0 d-flex justify-content-left instructions__p"
                    style="line-height: 36px"
                            v-html="contentPage.instance.favorite_influencer_instructions.content.values.title.value"></p>
                    <hr v-if="eventStatus == 'active'" class="mt-0 mb-0" style="color: #ff0000; height: 2px; opacity: .6"/>
                    <p  v-if="eventStatus == 'active'" class="main-p text-start smaller mb-3 mt-3 instructions__p"
                            v-html="contentPage.instance.favorite_influencer_instructions.content.values.description.value"></p>


                    <p v-if="eventStatus == 'registration_end' && eventStatus != 'finished'" class="main-p mb-0 d-flex justify-content-left instructions__p"
                    style="line-height: 36px"
                            v-html="contentPage.instance['votation-instructions'].content.values.title.value"></p>
                    <hr v-if="eventStatus == 'registration_end' && eventStatus != 'finished'" class="mt-0 mb-0" style="color: #ff0000; height: 2px; opacity: .6"/>
                    <p  v-if="eventStatus == 'registration_end' && eventStatus != 'finished'" class="main-p text-start smaller mb-3 mt-3 instructions__p"
                            v-html="replaceVariables(contentPage.instance['votation-instructions'].content.values.description.value)"></p>

                            
                    
                    <p v-if="eventStatus === 'active'" class="main-p mb-0 mt-5 d-flex justify-content-left instructions__p"
                    style="line-height: 36px"
                            v-html="contentPage.instance.polls_and_quizzes_instructions.content.values.title.value"></p>
                    <hr v-if="eventStatus === 'active'" class="mt-0 mb-0" style="color: #ff0000; height: 2px; opacity: .6"/>
                    <p v-if="eventStatus === 'active'" class="main-p text-start smaller mb-3 mt-3 instructions__p"
                            v-html="contentPage.instance.polls_and_quizzes_instructions.content.values.description.value"></p>
                </div>
                <button class="main-btn my-5" @click="logOut">
                    Cerrar sesión
                </button>
            </div>
        </div>
        <div v-else class="loader">
            <Loader></Loader>
        </div>
</template>

<script>
import LobbyTabs from './LobbyTabs.vue'
import Loader from './Loader.vue';
import orderedSocialNetworks from "./Mixins/orderedSocialNetworks";


import axios from 'axios';
export default {
    data() {
        return {
            loaded: false,
            contentPage: null,
            achievements: {
                polls: [],
                quizzes: []
            },
            // localMe: null,
            pollResults: [],
            quizResults: [],
            answeredForms: [],
            favoriteInfluencer: null,
          me: Object
        }
    },
    props: {
      // me: Object,
      eventStatus: String
    },
    mixins: [orderedSocialNetworks],
    components: { LobbyTabs, Loader },
  created() {
    this.getMe()
  },
    mounted: function () {
        // this.localMe = this.me;
        this.getSupportData();
        this.getExtraSupportData();
    },
    computed: {
        participant() {
            return this.favoriteInfluencer;
        }
    },
    methods: {
      getMe(){
        // var token = this.$cookies.get('mints_contact_id')
        let token = localStorage.getItem('mints_contact_id')

        if (token != null) {
          let localStorageKey = 'followers.contact.' + token
          this.me = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
        }
      },
        getSupportData() {
            if (this.me && this.me.id) {
                axios.post('api/v1/get-answered-polls_and_quizzes', {include_answers: true}).then(response => {
                    this.answeredForms = response.data
                }).catch(error => {
                    if(error.response.status == 440) {
                    this.$swal.fire({
                        title: 'La sesión ha expirado',
                        confirmButtonText: 'Regresar al inicio',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                        window.location = '/';
                        }
                    })
                    }
                });
            }
            axios.get('api/v1/get-content-page/profile').then(response => {
                this.contentPage = response.data.data
            }).catch((error) => {
                console.log(error);
            })
        },
        getExtraSupportData() {
            if (this.me && this.me.id) {
                axios.get('api/v1/get-profile-support-data').then(response => {
                    this.pollResults = response.data.results;
                    this.quizResults = response.data.quiz_results;
                    this.favoriteInfluencer = response.data.favorite
                    this.me.relationships['influencer'] = response.data.me.relationships.influencer || null
                }).catch(error => {
                    if(error?.response?.status == 440) {
                    this.$swal.fire({
                        title: 'La sesión ha expirado',
                        confirmButtonText: 'Regresar al inicio',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                        window.location = '/';
                        }
                    })
                    }
                });
            }
        },
        totalQuestions(quiz) {
            if (quiz.results ) {
                return Object.keys(quiz.results).length;
            } else {
                return 0;
            }
        },
        correctAnswers(quiz) {
            let count = 0;
            if (quiz.results) {
                for (let option in quiz.results) {
                    if (quiz.results.hasOwnProperty(option)) {
                        if (quiz.results[option].answered.is_correct) {
                            count = count + 1;
                        }
                    }
                }
            }   
            return count;
        },
        logOut() {
            axios.get('api/v1/logout').then(response => {
                this.$emit('clearSessionVars');
                this.$router.push('/')
            })

            // destroy all localStorage keys
            localStorage.clear();
        },
        capitalize(text) {
            text = text.toLowerCase();
            return text.split(' ').map(function(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        },
        socialNetworkIcon(type) {
            let slug = null;
            switch (type) {
                case 'Twitter':
                    slug = 'twitter-icon'
                    break;
                case 'Instagram':
                    slug = 'instagram-icon'
                    break;
                case 'Facebook':
                    slug = 'face-icon'
                    break;
            }
            return 'https://endemolcxfprod-16905.kxcdn.com/public-assets/' + slug
        },
        calculatePercent(answers, answer) {
            let total = this.calculateTotal(answers);
            let percent = (answer/total) * 100 || 0
            return percent.toFixed(2);            
        },
        calculateTotal(answers) {
            let total = 0 
            Object.keys(answers).forEach(key => {
                const value = answers[key];
                total = total + answers[key];
            });
            return total;
        },
        isActiveAnswer(poll_id, field, option) {
            let answeredForm = this.answeredForms.find(form => {
                return form.form_id === poll_id;
            });
            if (answeredForm) {
                let answers = JSON.parse(answeredForm.answers);
                return (answers[field] == option);
            }
             return false;
        },
        replaceVariables(text) {
            text = text.replace(/\{\{open-span\}\}/g, '<span class="font-weight-bold">');
            text = text.replace(/\{\{close-span\}\}/g, '</span>');
            text = text.replace(/\{\{br\}\}/g, '<br>');
            let givenName = this.me?.given_name || '';
            givenName = this.capitalize(givenName);
            text = text.replace(/\{\{name\}\}/g, givenName);
            return text;
        },
    }
};
</script>

<style lang="scss">
.fan-picture {
  margin-bottom: -30px
}
</style>
<style scoped>
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
</style>

<style lang="scss">
.profile-card__container {
  position: relative;
  width: 100%;

  &::after {
    left: -25px;
    top: 0;
    position: absolute;
    content: '';
    width: calc(100% + 50px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    z-index: -1;
  }
}

.instructions__p, .instructions__p p {
  margin-bottom: 0;
}

.accordion-button.profile-accordion {
  padding: 10px 40px;
  border-radius: 0px !important;
}

.main-p p {
  margin-bottom: 0;
}

.trivia-container {
  position: relative;
  padding: 15px 10px;
  width: 100%;

  &::after {
    left: -25px;
    top: 0;
    position: absolute;
    content: '';
    width: calc(100% + 50px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    z-index: -1;
  }
}

.trivia-question {
  width: 100%;
  margin-bottom: 15px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    transform: translate(-40%);
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>