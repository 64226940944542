<template>
  <div v-if="showLoader" class="d-flex justify-content-center">
    <div class="loader">
      <Loader ></Loader>
    </div>
  </div>
  <div v-else class="d-flex flex-column align-items-center w-100" id="login">
      <div class="d-flex flex-column align-items-center w-100">

      <p class="main-p biggest mt-4 mb-0 font-weight-bold">Iniciar sesión</p>
      

      <div class="d-flex flex-column align-items-center w-100" v-if="mode == 'otp'">
        <OptLogin @setLoader="setLoader"></OptLogin>
        <div class="d-flex text-white mt-3">
            <a href="#" class="text-decoration-underline mx-2 a-link mb-3" @click.prevent="setMode('magic-link')">Usar acceso seguro</a>
        </div>
      </div>
      
      <div v-if="mode == 'magic-link'" class="d-flex flex-column align-items-center w-100">
        <p class="main-p mb-5">Ingresa tu email</p>
        <AuthForm 
          v-if="mode == 'magic-link'"
          ref="authForm"
          :options="options"
          @onSubmit="onSubmit"
          @onError="onError"
          @changeMode="changeMode"/>
        <div v-if="showMessage" class="d-flex text-white mt-3">
          ¿No te llegó el Acceso Seguro? Revisa que el email sea el mismo con el que te registraste.
        </div>
        <div class="d-flex text-white mt-3">
          <a href="#" class="text-decoration-underline mx-2 a-link mb-3" @click.prevent="setMode('otp')">Usar código temporal</a>
        </div>
      </div>
     
    </div>

    <div class="d-flex text-white mt-3 mb-5" v-if="eventStatus === 'active'">
      ¿No tienes una cuenta? <RouterLink class="text-decoration-underline mx-2 a-link" to="/registro">regístrate</RouterLink>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import AuthForm from "./AuthForm.vue";
import Oauth from '@helpers/oauth.js';
import OptLogin from '@mints-components/otp-login/App.vue';
import Loader from '@views/home/Loader.vue'

export default defineComponent({
  mixins: [Oauth],

  props: {
    options: {
      type: Object,
      default: () => ({ magicLink: { enabled: true }, password: { enabled: true } })
    },
    eventStatus: String
  },
  components: { AuthForm, OptLogin, Loader },
  data: () => ({ 
    message: 'Ingresa tu correo y recibe el enlace de acceso',
    showMessage: false,
    email: null,
    mode: 'otp',
    showLoader: false
  }),
  methods: {
    resetErrors() { this.$refs.authForm.resetErrors() },
    onSubmit(response) { 
    
      this.$emit('onSubmit', response);
      this.email = response.credentials.email;
      this.showMessage = true; 
    },
    onError(error) { this.$emit('onError', error) },
    changeMode(mode) { this.message = mode ? 'Inicia sesión con tu correo y contraseña' : 'Ingresa tu correo y recibe el enlace de acceso' },
    setMode(mode) {
      this.mode = mode;
      this.showMessage = false;
    },
    setLoader(val) {
      this.showLoader = val;
    }
  }
})
</script>

<style >
@import "styles.css";
</style>