export default {
    created() {
        this.initializeOauthComponents();
    },

    methods: {
        initializeOauthComponents() {
            let container = document.getElementById('oauth-components');
            let container2 = document.getElementById('oauth-logins');

            if (container) {
                let clone = container2.cloneNode(true)
                clone.classList.remove('d-none')
                container.appendChild(clone)
            } else {
                setTimeout(() => {
                    this.initializeOauthComponents()
                }, 50)
            }
        }
    }
}