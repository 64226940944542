<template>
    
    <div class="d-flex flex-column align-items-center w-100">
        <div class="dd-flex flex-column align-items-center w-100">
    
        <!-- <iframe width="500" height="315" src="http://telemundo-vh.akamaihd.net/z/MPX/video/NBCU_Telemundo/20200508/4165570_1588980647/200508_4165570_Carmen_Aub_no_pudo_contener_las_lagrimas_al__anvver_1_4000.mp4?hdnea=st=1682543790~exp=1682551020~acl=/z/MPX/video/NBCU_Telemundo/20200508/4165570_1588980647/200508_4165570_Carmen_Aub_no_pudo_contener_las_lagrimas_al__anvver_1_*~id=72c7882d-c414-4e5b-96f9-2fc2979d3570~hmac=fe966a7986f04204d534034fc85799bed9210798cc58fb210b706f59bb2c49b8"></iframe> -->
        <!-- <video src="http://telemundo-vh.akamaihd.net/z/MPX/video/NBCU_Telemundo/20200508/4165570_1588980647/200508_4165570_Carmen_Aub_no_pudo_contener_las_lagrimas_al__anvver_1_4000.mp4?hdnea=st=1682543790~exp=1682551020~acl=/z/MPX/video/NBCU_Telemundo/20200508/4165570_1588980647/200508_4165570_Carmen_Aub_no_pudo_contener_las_lagrimas_al__anvver_1_*~id=72c7882d-c414-4e5b-96f9-2fc2979d3570~hmac=fe966a7986f04204d534034fc85799bed9210798cc58fb210b706f59bb2c49b8" system-bitrate="4161476" height="1080" width="1920"/> -->
        <center>
            <div style="color:white"  v-if="xmlJson" v-for="item in xmlJson.rss.channel.item">
                <h4>{{ item.title }}</h4>
                <img :src="item.defaultThumbnailUrl" width="860" height="340">
                <!-- <div v-for="thumbnail in item.thumbnail">
                    <img v-if="thumbnail.isDefault == 'true'" :src="thumbnail.url" width="400" height="300">
                    <br/>
                </div> -->
                <!-- <div style="color:white" v-for="video in item.group.content">
                    {{ video.url }}
                    <iframe width="560" height="315" :src="video.url" frameborder="0" allowfullscreen></iframe>

                </div> -->
            </div>
        </center>
            
        <button class="main-btn mb-4">
            <span> Enviar </span>
        </button>

        </div>
    </div>
</template>

<script>

import axios from 'axios';
export default {
  data() {
    return {
        xmlJson: null
    }
  },
  mounted: function() {
    this.FeedXml();
  },
  methods: {
    FeedXml() {
        axios.get('api/v1/feed-xml').then(response => {
            this.xmlJson = response.data;
        }).finally(() => {

        });
    },
  }
};
</script>
