    <template>
        <div>
            <div class="icon" v-if="showIcon">
                <button class="btn btn-link" @click="show = true"> <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-notificaciones" alt=""></button>
            </div>
            <CaliModal ref="ModalShare" modalSize="sm" v-if="show" @close="show = false">
            <template v-slot:header>
                <h4 class="modal-title" id="exampleModalLabel"></h4>
            </template>
            
            <template v-slot:body>     
                
                <div v-if="!isPWA">
                    <p class="main-p font-weight-bold" v-if="isPC"> Crea un acceso directo de esta aplicación en tu celular <span>para poder recibir notificaciones.</span></p>
                    <p class="main-p font-weight-bold" v-else> Crea un acceso directo de esta aplicación <span>para poder recibir notificaciones.</span></p>
                    <p class="main-p">Elige el sistema operativo de tu Teléfono</p>

                    <center>
                    <div class="btn-group btn-group-toggle hotfix-class" data-toggle="buttons">
                        <label class="btn btn-secondary btn-notifty d-flex justify-content-center align-items-center" :class="{'active': selectedSystem == 'IOS'}">
                            <input type="button" name="options" @click="selectedSystem = 'IOS'"  autocomplete="off"> iOS
                        </label>
                        <label class="btn btn-secondary btn-notifty d-flex justify-content-center align-items-center" :class="{'active': selectedSystem == 'Android'}">
                            <input type="button" name="options" @click="selectedSystem='Android'"  autocomplete="off"> Android
                        </label>
                    
                    </div>
                    </center>
                    <div v-if="selectedSystem == 'Android'">
                        <p class="main-p">
                            <span>1.- Da tap en el ícono</span> &nbsp;<img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-dots-android" style="display:inline">&nbsp; que se encuentra a un lado de la barra de navegación
                            <span v-if="isPC"> de Chrome en tu celular</span>
                        </p>
                        
                        <p class="main-p"><span>2.-</span> Selecciona la opción "<span>Añadir a pantalla de inicio</span>"</p>
                        <p class="main-p"><span>3.-</span> Seleccionamos el nombre con el que deseamos identificar la aplicación y presionamos en "<span>Añadir</span>"</p>
                        <p class="main-p"><span>4.-</span> Acepta el envío de notificaciones push en tu celular y <span>otorga los permisos</span> cuando se te solicite</p>
                        
                        <p class="main-p"> <span>¡Listo ahora podrás recibir notificaciones y no te perderás de nada!</span></p>
                    </div>
                    <div v-if="selectedSystem == 'IOS'">
                        <p class="main-p">
                            <span>1.- Da tap en el botón de compartir</span> que se encuentra en la barra de navegación
                            <span v-if="isPC"> de Safari en tu celular</span>
                        </p>
                        <p class="main-p"><span>2.-</span> Selecciona la opción "<span>Añadir a la pantalla de inicio</span>"</p>
                        <p class="main-p"><span>3.-</span> Da tap en "<span>Añadir</span>" y confirma la acción</p>
                        <p class="main-p"><span>4.-</span> Acepta el envío de notificaciones push en tu celular y <span>otorga los permisos</span> cuando se te solicite</p>

                        <p class="main-p"> <span>¡Listo ahora podrás recibir notificaciones y no te perderás de nada!</span></p>
                    </div>
                </div> 
            </template>
            
            <template v-slot:footer>
                <button type="button" class="main-btn simple-btn" @click="show = false">Cerrar</button>
            </template>
            </CaliModal>
        </div>
    </template>
    <script>

    import CaliModal from '@mints-components/cali-modal/App.vue';

  export default {
    name: "App",
    
    components: { CaliModal},
    props: {
      
    },
    data() {
        return {
            show: false,
            selectedSystem: 'Android'
        }
    },
    computed: {
        isAndroid() {
            return /Android/i.test(navigator.userAgent);
        },
        isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },
        isPC() {
        const userAgent = navigator.userAgent;
            return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        },
        showIcon() {
            return (!this.isPWA && (this.isAndroid || this.isIOS || this.isPC));
            // return (!this.isPWA && (this.isAndroid));
        },
        isPWA() {
            return ("navigator" in window) && ("standalone" in window.navigator && window.navigator.standalone);
        },
        isChrome() {
            const isIOSChrome = /CriOS/i.test(navigator.userAgent);
            const isDesktopChrome = /Chrome/i.test(navigator.userAgent) && /Edg|OPR/i.test(navigator.userAgent) === false;
            return isIOSChrome || isDesktopChrome;

        },
        isSafari() {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        },
    },
    created() {
        
    },
    mounted() {
        if(this.isIOS) {
            this.selectedSystem = 'IOS';
        } else if (this.isAndroid){
            this.selectedSystem = 'Android'
        };
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('pwa-instructions')) {
            let param = urlParams.get('pwa-instructions');
            if (param == 1) {
                this.show = true;
            }
        }
    },
    
    methods: {
        
    }
  };
  </script>

  <style lang="scss" scoped>
        .icon {
            position: absolute;
            top: 3px;
            right: 5px;
            color:white
        }

        .main-p {
            color: black;
        }
        .btn-notifty{
            background-color: #34035E;
            opacity: 50%;
            padding: 6px 50px;
            font-weight: 700;
            color: white;
            border-radius: 10px;
        }

        .active {
            background-color: #34035E;
            opacity: 100%;
        }
        @media (max-width:379px){
            .hotfix-class{
                flex-wrap: wrap;
                gap: 5px;
                label{
                    border-radius: 10px !important;
                }
            }
        }
        
  </style>

  