<template>
  <div>
    <div class="d-flex justify-content-center" style="gap: 30px;">
      <ShareNetwork v-if="icons" :popup="{width: 400, height: 200}"
        @close="close"
        v-for="icon in icons" :key="icon.content_instance_id"
        :network="icon.values.network.value"
        :url="participant.campaign_url"
        :title="title"
        :description="description"
        :quote="participant.nickname"
        :hashtags="'los50'"
      >
        <img alt="" width="35" height="35" :src="icon.values.icon.value.public_path">
      </ShareNetwork>
      <div>
        <a href="#" v-if="copyUrlButton" @click.prevent="copyUrl()">
          <img :src="copyUrlButton.values.image.value.public_path" 
              alt="" 
              width="35"
              height="35"
              class="social-participant__icon"
          />
        </a>
      </div>
    </div>
    <p class="d-flex mt-5 small">Compartir en redes sociales no incrementará tus probabilidades de ganar un premio en este Sorteo</p>
  </div>
  </template>
  
  <script>
  
  import {SocialSharing, ShareNetwork} from 'vue-social-sharing';
  import axios from 'axios';
  export default {
    data() {
        return {
            icons: null,
            copyUrlButton: null
        }
    },
    props: {
      participant: { type: Object, required: true },
    },
    components: {SocialSharing, ShareNetwork},
    mounted: function() {
        this.getSupportData();
    },
    computed: {
        title() {
          return '¿Ya estás viendo Los 50? Mi jugador favorito es '+this.participant.hashtag+'. ¡Conócelo y elígelo como tu favorito!'
        },
        description() {
          return '¡Sígueme en Los 50 por Telemundo! <br/> ¡Sé parte del show desde la App de Los 50, elige a tu jugador favorito y gana $50,000 USD! '
        },
        hashtag() {
          if (this.participant.hashtag.startsWith("#")) {
            return this.participant.hashtag.slice(1);
          } else {
            return this.participant.hashtag;
          }
        }
    },
    methods: {
        getSupportData() {
            axios.get('api/v1/get-content-page/sharing-network').then(response => {
                this.icons = response.data.data.template.icons.content || null
                this.copyUrlButton = response.data.data.instance.copy_url_button.content || null
            }).catch((error) => {
                console.log(error);
            })
        },
        close() {
            this.$emit('close');
        },
        copyUrl() {
            const tempElement = document.createElement('textarea');
            tempElement.value = this.participant.campaign_url;
            document.body.appendChild(tempElement);
            tempElement.select();
            document.execCommand('copy');
            document.body.removeChild(tempElement);
            this.sendAlert('success', "Enlace copiado", "Se copió el enlace para compartir en el portapapeles")
        },
        sendAlert(icon, title, text) {
        this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        })
      }
    }
  }
  </script>
  
  <style>
  
  </style>