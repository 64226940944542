<template>
    <div>
      <CaliLogin
        :options="{
          magicLink: { enabled: true, url: '/api/v1/login/send-magic-link'},
          password: { enabled: true, url: '/api/v1/login' },
          headers: {
            'x-api-key': '9c6334a7-8215-46ff-8ec9-f442cdc43cac'
          }
        }"
        :eventStatus="eventStatus"
        @onSubmit="onSubmit"
        @onError="onError"
      />

    </div>
  </template>
  
  <script>
  import CaliLogin from '@mints-components/cali-login/App.vue';
  import axios from 'axios';

  export default {
    components: { CaliLogin },

    data() {
      return {
        loaded: false,
      }
    }, 

    props: {
      eventStatus: String
    },

    methods: {
      onSubmit(response) {
        let title = 'Correo enviado.'
        let text = `Si tu correo está registrado, en unos instantes te llegará tu acceso seguro a ${response.credentials.email} para poder acceder a la plataforma.`
        if (response.type === 'password') {
          title = 'Autenticación correcta'
          text = 'Iniciando sesión...';

          setTimeout(() => {
            window.location.href = '/'
          }, 1500)
        }

        this.sendAlert('success', title, text)
      },
  
      onError(e) {
        this.sendAlert('error', 'Credenciales inválidas.', 'Parece que no tienes acceso a esta sección de la aplicación')
      },

      sendAlert(icon, title, text) {
        this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        })
      }
    }
  };
  </script>