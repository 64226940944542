import axios from 'axios';
export default {
  methods: {
    checkForData() {
        if (!this.me.hasOwnProperty('email') || this.me.email === null) {
            if(this.event_status === 'active') {
                this.$router.push('/datos-extra-adicionales');
            } else {
                this.sendAlert();
            }
            return;
        }

        if (
            this.me.hasOwnProperty('phone') &&
            this.me.hasOwnProperty('zip_code') &&
            this.me.hasOwnProperty('time_zone') &&
            this.me.hasOwnProperty('confirm_age') &&
            this.me.hasOwnProperty('privacy_policy') &&
            this.me.hasOwnProperty('terms_and_conditions_accepted')
        ) {
            // Check if the property values are null, undefined or empty string
            if (
                this.me.phone &&
                this.me.zip_code &&
                this.me.time_zone &&
                this.me.confirm_age &&
                this.me.privacy_policy &&
                this.me.terms_and_conditions_accepted 
            ) {
                // if all the values are present and the current view is the additional form, 
                // redirect to lobby 
                if(this.$route.name == 'Additional') {
                    this.$router.push('/lobby') 
                }
            // if one or more values are not present redirect to additional data form
            } else {
                if(this.event_status === 'active') {
                    this.$router.push('/datos-adicionales')
                } else {
                    this.sendAlert();
                }
            }
        } else {
            if(this.event_status === 'active') {
                this.$router.push('/datos-adicionales')
            } else {
                this.sendAlert();
            }
        }
        
    },
    logOut() {
        axios.get('api/v1/logout').then(response => {
            this.$emit('clearSessionVars');
            window.location.href = '/login'
        })
    },
    sendAlert() {
        this.$router.push('/datos-adicionales') ;
        this.$swal.fire({
            title: 'Registro cerrado',
            text: "Se cerró el registro, continua con una cuenta existente",
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Continuar'
          }).then((result) => {
            this.logOut();
        })
    },
  }
}