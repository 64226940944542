<template>
  <div v-if="contentPage && me" class="d-flex flex-column align-items-center w-100 mb-5" id="trivia-thanks">
    <div class="d-flex flex-column align-items-center w-100">

      <div class="logo-container small mt-3 mb-4">
        <img :src="contentPage.instance.header.content.values.image.value.public_path" alt="" class="los-50-logo">
      </div>

      <section class="" v-if="!areThereQuizzes && !areTherePolls">
        <p class="main-p mt-4 mb-1 font-weight-bold w-75" style="line-height:24px"
           v-html="replaceVariables(contentPage.instance.empty_header.content.values.title.value)"></p>
        <p class="main-p  mt-4 mb-1 w-75" style="line-height:24px"
           v-html="replaceVariables(contentPage.instance.empty_header.content.values.description.value)"></p>
        <button class="main-btn py-1 mt-5" @click="$router.push('/lobby')">
          Regresar al inicio
        </button>
      </section>
      <section v-if="areThereQuizzes" class="d-flex flex-column align-items-center w-100">
        <p class="main-p bigger mt-4 mb-1 font-weight-bold"
           v-html="contentPage.instance.quizzes_header.content.values.title.value"></p>
        <div v-for="quiz in quizzes" :key="quiz.id" class="w-100">
          <div v-if="checkIfFormHasQuestions(quiz)">
            <p class="main-p mt-1 mb-3" v-html="quiz.title"></p>
            <button v-if="answeredQuiz(quiz.id, quiz.slug) == 'available'" class="main-btn mt-2 mb-3"
                    @click="$router.push('/trivia/'+quiz.slug)">
              {{ contentPage.instance.answer_quiz_button.content.values.text.value }}
            </button>
            <button v-else-if="answeredQuiz(quiz.id, quiz.slug) == 'in-progress'" class="main-btn mt-1 mb-3"
                    @click="$router.push('/trivia/'+quiz.slug)">
              {{ contentPage.instance.progress_quiz_button.content.values.text.value }}
            </button>
            <button v-else class="disabled-btn mt-1 mb-3" @click="$router.push('/trivia/'+quiz.slug)" disabled>
              {{ contentPage.instance.answered_quiz_button.content.values.text.value }}
            </button>
          </div>
        </div>
      </section>

      <section v-if="areTherePolls" class="d-flex flex-column align-items-center w-100 encuesta-section pb-4 mt-4">
        <p class="main-p bigger mt-4 mb-0 font-weight-bold"
           v-html="contentPage.instance.polls_header.content.values.title.value"></p>
        <div v-for="poll in polls" :key="poll.id" class="w-100">
          <div v-if="checkIfFormHasQuestions(poll)">
            <p class="main-p mb-2 mt-1"><span class="">{{ poll.title }}</span></p>
            <button v-if="answeredPoll(poll.id, poll.slug) == 'available'" class="main-btn white-btn mb-3"
                    @click="$router.push('/encuesta/'+poll.slug)">
              {{ contentPage.instance.answer_poll_button.content.values.text.value }}
            </button>
            <button v-else-if="answeredPoll(poll.id, poll.slug) == 'in-progress'"
                    class="main-btn light simple-btn mt-2 mb-3" @click="$router.push('/encuesta/'+poll.slug)">
              {{ contentPage.instance.progress_poll_button.content.values.text.value }}
            </button>
            <button v-else class="disabled-btn mb-3" @click="$router.push('/encuesta/'+poll.slug)" disabled>
              {{ contentPage.instance.answered_poll_button.content.values.text.value }}
            </button>
          </div>
        </div>
      </section>

      <div class="d-flex text-white mt-5">
        <a href="https://www.telemundo.com/shows/los-50" target="_blanck" class="text-decoration-underline mx-2 a-link">Más
          contenido de Los 50 en Telemundo</a>
      </div>
    </div>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>
</template>

<script>
import LobbyTabs from './LobbyTabs.vue'

import axios from 'axios';
import VideosList from './VideosList.vue';
import Loader from './Loader.vue';

export default {
  data() {
    return {
      loaded: false,
      contentPage: null,
      polls: [],
      quizzes: [],
      videosSource: null,
      // localMe: null,
      answeredForms: [],
      me: Object
    }
  },
  props: {
    // me: Object,
    eventStatus: String
  },
  components: {LobbyTabs, VideosList, Loader},
  mounted: function () {
    if (this.eventStatus != 'active') {
      this.$router.push('/lobby')
    }
    this.getSupportData();
  },
  computed: {
    areThereQuizzes() {
      let areThereQuizzes = true;
      if (this.quizzes.length <= 0) {
        areThereQuizzes = false;
      } else {
        areThereQuizzes = this.quizzes.some(quizz => this.checkIfFormHasQuestions(quizz));
      }
      return areThereQuizzes;
    },
    areTherePolls() {
      let areTherePolls = true;
      if (this.polls.length <= 0) {
        areTherePolls = false;
      } else {
        areTherePolls = this.polls.some(poll => this.checkIfFormHasQuestions(poll));
      }
      return areTherePolls;
    }
  },
  watch: {
    me: {
      handler: function (newItem) {
        if (newItem) {
          this.localMe = newItem
          this.getAnsweredPollsAndQuizzes()
        }
      },
      immediate: true
    },
  },
  created() {
    this.getAnsweredPollsAndQuizzes();
    this.getMe();
  },
  methods: {
    getMe(){
      var token = localStorage.getItem('mints_contact_id');

      if (token != null) {
        let localStorageKey = 'followers.contact.' + token
        this.me = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
      }
    },
    getAnsweredPollsAndQuizzes() {
      axios.post('api/v1/get-answered-polls_and_quizzes').then(response => {
        this.answeredForms = response.data
      }).catch(error => {
        if (error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      });
    },
    getSupportData() {
      axios.get('api/v1/get-mechanics-support-data').then(response => {
        this.contentPage = response.data.contentPage;
        this.polls = response.data.polls || [];
        this.quizzes = response.data.quizzes || [];
        this.videosSource = this.contentPage.instance.videos_source.content.values.url.value
      }).catch((error) => {
        console.log(error);
      })
    },
    answeredPoll(formId, formSlug) {
      if (this.answeredForms.includes(formId)) {
        return 'completed';
      } else {
        let localStorageKey = 'mechanics.polls.' + formSlug + '.' + this.me.id
        let answers = JSON.parse(window.localStorage.getItem(localStorageKey + '.answers')) || null;
        if (answers) {
          return 'in-progress';
        } else {
          return 'available';
        }
      }
    },
    answeredQuiz(formId, formSlug) {
      if (this.answeredForms.includes(formId)) {
        return 'completed';
      } else {
        let localStorageKey = 'mechanics.quizzes.' + formSlug + '.' + this.me.id
        let answers = JSON.parse(window.localStorage.getItem(localStorageKey + '.answers')) || null;
        if (answers) {
          return 'in-progress';
        } else {
          return 'available';
        }
      }
    },

    checkIfFormHasQuestions(form) {
      let configJson = form?.config_json || {fields: []};
      let fields = configJson.fields || [];
      if (fields.length > 1) {
        return true;
      }
      return false;
    },

    replaceVariables(text) {
      text = text.replace(/\{\{open-span\}\}/g, '<span class="font-weight-bold">');
      text = text.replace(/\{\{close-span\}\}/g, '</span>');
      text = text.replace(/\{\{br\}\}/g, '<br>');
      return text;
    },
  }
};
</script>

<style lang="scss" scoped>
.encuesta-section {
  position: relative;
  width: 100%;

  &::after {
    left: -25px;
    top: 0;
    position: absolute;
    content: '';
    width: calc(100% + 50px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    z-index: -1;
  }
}</style>