<template>
    
    <div class="mx-2 d-flex w-100 justify-content-center">
        
        <div v-if="mode == 'mail'" class="d-flex flex-column align-items-center w-100">
          
          <p class="main-p mb-5">Ingresa tu email</p>
          
          <input type="email" v-model="email" class="main-input w-100 mb-4"
          style="min-height: 46px"
            :class="{'cali__login-error': !isValidEmail}"
            name="code"
            placeholder="Ingresa tu email"
            @keyup.enter="otpSend"
            @input="validateEmailDebonuce"/>

          <button class="main-btn mb-3 align-items-center" @click="otpSend()" :disabled="loading"> 
            <span v-if="loading" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
            <span>Enviar </span>
          </button>
        </div>
        <div v-if="mode == 'code'" class="d-flex flex-column align-items-center w-100">
          <p class="main-p mb-5">Ingresa tu código</p>
          <OtpInput 
            @complete="completed = true"
            @change="changeOtp"
            v-model="otp"
            :fields="6"
            :fieldWidth="40"
            :fieldHeight="40"
            :class="{'cali__login-error': !isValidCode}"
            :isValidCode="isValidCode"
            :required="true">
          </OtpInput>
          <div class="text-white mt-4 d-flex justify-content-center">
            <p v-if="disabledResendBtn" class=" mx-2 a-link mb-3">
                Podrás reenviar el correo en {{ countdown }} segundos.
            </p>
            <span v-else class="d-flex"> ¿No te llegó?
              <a  href="#" class="text-decoration-underline mx-2 a-link mb-3" @click.prevent="setMailMode()"> 
                Reenviar código
              </a>
            </span>
          </div>
          <button class="main-btn mb-3 mt-2 align-items-center" @click="otpLogin()" :disabled="loading"> 
            <div class="d-flex align-items-center justify-content-center">
              <span v-if="loading" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
                  <span>Iniciar sesión </span>
              </div>
          </button>
        </div>

        
    </div>
  </template>
  <script>
  import axios from 'axios';
  import _ from 'lodash';
  import OtpInput from './OtpInput.vue'
  
 
  export default {
    data() {
      return {
        otp: '' ,
        email: '',
        mode: 'mail',
        loading: false,
        isValidEmail: true,
        isValidCode: true,
        completed: null,
        countdown: 0,
        disabledResendBtn: false,
      }
    },
    props: {
      setEmail: {
        type: String,
        default: ''
      },
      setMode: {
        type: String,
        default: 'mail'
      }
    },
    components: {OtpInput},
    mounted() {
      this.email = this.setEmail;
      this.mode = this.setMode;
    },
    methods: {
        changeOtp(e) {
          this.otp = parseInt(e);
        },
        otpSend() {
          this.validateEmail()
          if (this.isValidEmail) {
            this.loading = true;
            axios.post('api/v1/otp-send', {email: this.email}).then(response => {
              if (response.data) {
                this.disableButton();
                this.sendAlert('success', 'Correo enviado.', `Si tu correo está registrado, en unos instantes te llegará tu código temporal a ${this.email} para poder acceder a la plataforma.`)
                this.mode = 'code'
              }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
              this.loading = false;
            })
          }
        },
      otpLogin() {
          this.validateCode()
          if (this.isValidCode) {
            this.loading = true;
            axios.post('api/v1/otp-login', {email: this.email, code: this.otp}).then(response => {
              if (response.data) {
                // Set token in localStorage
                localStorage.setItem('mints_contact_token', response.data.mints_contact_token);
                axios.defaults.headers.common['custom-auth'] = response.data.mints_contact_token;
                localStorage.setItem('mints_contact_id', response.data.id);

                this.$emit('setLoader', true);
                let id = response.data.id;
                let localStorageKey = 'followers.contact.' + id;
                this.getData(localStorageKey, id);
              } else {
                this.sendAlert('error', 'Código temporal no válido', 'El código temporal no es válido o ya expiró')
              }
            }).catch((error) => {
              this.$emit('setLoader', false);
                console.log(error);
            }).finally(() => {
              this.loading = false;
            })
          }
        },
      async getData(localStorageKey, id){
        await axios.post('api/v1/get-session', {id: id}).then(response => {
          if (response.data) {
            let contactData = response.data;
            this.updateLocalStorage(localStorageKey, contactData);
          }
        });
      },
      updateLocalStorage(localStorageKey, contact) {
        let me = {
          'id': contact.id,
          'mints_contact_id': contact.token_id,
          'given_name': contact.given_name,
          'last_name': contact.last_name,
          'phone': contact.phone || null,
          'email': contact.email || null,
          'zip_code': contact.zip_code_2024 || null,
          'time_zone': contact.time_zone_2024 || null,
          'confirm_age': contact.confirm_age_2024 || null,
          'privacy_policy': contact.privacy_policy_2024 || null,
          'terms_and_conditions_accepted': contact.terms_and_conditions_accepted_2024 || null,
          'send_promotions_and_news': contact.send_promotions_and_news_2024 || null,
          'full_name': contact.full_name,
          'display_name': contact.display_name,
          'app_status': contact.app_status,
          'device': contact.device,
        }
        window.localStorage.setItem(localStorageKey, JSON.stringify(me));
        this.$router.push({ path: '/lobby' })
      },
        setMailMode() {
          this.email = '';
          this.mode = 'mail';
        },
        validateEmailDebonuce: _.debounce(function () {
          this.validateEmail();
        },2500),
        validateEmail: function () {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          this.isValidEmail = emailRegex.test(this.email);
        },
        validateCodeDebonuce: _.debounce(function () {
          this.validateCode();
        },2500),
        validateCode: function () {
          const emailRegex = /^\d{6}$/;
          this.isValidCode = emailRegex.test(this.otp);
        },
        sendAlert(icon, title, text) {
          this.$swal.fire({
            icon: icon,
            title: title,
            text: text,
            confirmButtonText: "Aceptar",
          })
        },
        disableButton() {
          this.disabledResendBtn = true;
          this.countdown = 60;
          for (let i = 0; i < 60; i++) {
              setTimeout(() => {
                  this.countdown--;
              }, i * 1000);
          }
        
          setTimeout(() => {
              this.disabledResendBtn = false;
          }, 60000);
        },
        
    }
  };
  </script>
  <style lang="scss">
    .loading {
      width: 15px;
      height: 15px;
      animation: spin 1s infinite linear;
      filter: invert(100%);
      margin-right: 5px;
    }

    .button-items {
      display: flex;
      align-items: center;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  
  </style>