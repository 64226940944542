<template>
  <div v-if="loading" class="loader">
    <Loader></Loader>
  </div>
  <div v-else-if="form && currentField" class="d-flex flex-column align-items-center w-100" id="Encuesta">
    <div v-if="!completedForm" class="d-flex flex-column align-items-center w-100">

      <!--            <span class="main-p smallest text-left font-weight-bold w-100 mb-2"> -->
      <!--                <RouterLink to="/interacciones" class="d-flex flex-nowrap mb-4 back-div" style="gap:10px">-->
      <!--                    <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon">-->
      <!--                    <div class="back-div">Regresar</div>-->
      <!--                </RouterLink>-->
      <!--            </span>-->
      <p class="main-p bigger mt-4 font-weight-bold">Encuesta</p>
      <p class="main-p mb-0">
        <span>{{ currentFieldNumber }}</span>
        /
        <span>{{ form.config_json.fields.length }}</span>
      </p>
      <p class="main-p text-center mb-4 mt-4" v-html="currentField.title"></p>

      <form class="w-100 d-flex flex-column align-items-start mb-4" style="max-width: 500px;">
        <button type="button" v-for="(option, index) in currentField.list_options" :key="index"
                class="btn-encuesta alternative result mb-3"
                :class="{'active': selectedAnswer == option}"
                @click="selectedAnswer = option">
          <div class="d-flex align-items-center" style="gap:10px">
            <span></span>
            {{option}}
          </div>
        </button>
      </form>

      <button class="main-btn mb-3"
              :class="{'disabled-btn': !selectedAnswer, 'main-btn': selectedAnswer}"
              :disabled="!selectedAnswer"
              @click="sendAnswer(selectedAnswer)">
        Enviar
      </button>

    </div>
    <div v-else class="d-flex flex-column align-items-center w-100">
      <!--            <span class="main-p smallest text-left font-weight-bold w-100 mb-2"> -->
      <!--                <RouterLink to="/interacciones" class="d-flex flex-nowrap mb-4 back-div" style="gap:10px">-->
      <!--                    <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon">-->
      <!--                    <div class="back-div">Regresar</div>-->
      <!--                </RouterLink>-->
      <!--            </span>-->
      <p class="main-p bigger mt-4 font-weight-bold">Encuesta</p>

      <p class="main-p text-center my-5 bigger">¡Muchas gracias por <br/><br/> contestar la encuesta!

      </p>
      <button class="main-btn mb-3" @click="$router.push('/interacciones')">
        Regresar a las interacciones
      </button>
    </div>
    <p class="main-p smaller">Participar en la Encuesta no incrementará tus probabilidades de ganar un premio en este Sorteo</p>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>
</template>

<script>

import axios from 'axios';
import LobbyTabs from './LobbyTabs.vue'
import moment from 'moment-timezone';
import Loader from './Loader.vue';

export default {
  data() {
    return {
      loaded: false,
      form: null,
      currentFieldIndex: 0,
      currentAnswers: {},
      completedForm: false,
      results: null,
      loading: false,
      selectedAnswer: null,
      answeredForms: []
    }
  },
  props: {
    me: Object
  },
  components: { LobbyTabs, Loader },
  mounted: function() {

  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    currentFieldNumber() {
      return this.currentFieldIndex + 1;
    },
    currentField() {
      return this.form.config_json.fields[this.currentFieldIndex] || this.form.config_json.fields[0];
    },
    localStorageKey() {
      return 'mechanics.polls.' + this.slug + '.' + this.me.id;;
    },
    timeZone() {
      let timeZone =  this.me?.time_zone || 'Montaña'
      let timeZoneName = "America/Denver";

      if (timeZone === "Este") {
        timeZoneName = "America/New_York";
      } else if (timeZone === "Central") {
        timeZoneName = "America/Chicago";
      } else if (timeZone === "Montaña") {
        timeZoneName = "America/Denver";
      } else if (timeZone === "Pacífico") {
        timeZoneName = "America/Los_Angeles";
      } else if (timeZone === "Alaska") {
        timeZoneName = "America/Anchorage";
      } else if (timeZone === "Islas Hawaii") {
        timeZoneName = "Pacific/Honolulu";
      } else if (timeZone === "Atlántico") {
        timeZoneName = "America/Puerto_Rico";
      }
      return timeZoneName;
    },
    isFormCompleted() {
      if (this.form != null) {
        return (this.answeredForms.includes(this.form.id));
      }
      return false;
    }
  },
  watch: {
    me: {
      handler: function (newItem) {

        if (newItem) {
          this.getSupportData();
        }
      },
      immediate: true
    },
    isFormCompleted(newVal) {
      if (newVal == true) {
        this.setFormAsCompleted();
      }
    }
  },
  methods: {

    getSupportData() {
      axios.post('api/v1/get-answered-polls_and_quizzes').then(response => {
        this.answeredForms = response.data
      }).catch(error => {
        if(error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      });
      axios.get('api/v1/get-form?slug='+this.slug).then(response => {
        let form = response.data;
        // let currentDate = moment().tz(this.timeZone);
        // if(!currentDate.isBetween(form.available_from, form.available_to)) {
        //     this.$router.push('/interacciones')
        // }
        let filteredFields = form.config_json.fields.filter(field => field.data_type_enum === 40);
        form.config_json.fields = filteredFields;
        this.form = form;
        this.getCurrentFieldIndex();
        this.getCurrentAnswers();
      }).catch((error) => {
        console.log(error);
        this.$router.push('/interacciones')
      })
    },
    sendAnswer(option) {
      this.currentAnswers[this.currentField.slug] = option;
      let nextIndex = this.currentFieldIndex + 1
      if (nextIndex >= 0 && nextIndex < this.form.config_json.fields.length) {
        this.currentFieldIndex = nextIndex;
      } else {
        this.completeForm();
      }
      this.setCurrentFieldIndex(nextIndex);
      this.setCurrentAnswers(this.currentAnswers);
      this.selectedAnswer = null;
    },
    setFormAsCompleted() {
      if (!this.completedForm) {
        this.completedForm = true;
      }
    },
    completeForm() {
      this.loading = true;
      let cali_token = localStorage.getItem('contact_token_cali') ? localStorage.getItem('contact_token_cali') : ''
      axios.post('api/v1/submit-poll', {
        id: this.form.id,
        title: this.form.title,
        slug: this.slug,
        answers: this.currentAnswers,
        contact_token_cali: cali_token,
      }).then(response => {
        this. setFormAsCompleted();
      }).catch(error => {
        if(error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      }).finally(()=>{
        this.loading = false;
      })
    },
    getCurrentFieldIndex() {
      let currentFieldIndex = parseInt(window.localStorage.getItem(this.localStorageKey+'.fieldIndex')) || 0;
      if (this.form.config_json.fields.length && currentFieldIndex >= this.form.config_json.fields.length){
        this.setFormAsCompleted()
      } else {
        this.currentFieldIndex = currentFieldIndex;
      }

    },
    setCurrentFieldIndex(index) {
      window.localStorage.setItem(this.localStorageKey+'.fieldIndex', index);
    },
    getCurrentAnswers() {
      this.currentAnswers = JSON.parse(window.localStorage.getItem(this.localStorageKey+'.answers')) || {};
    },
    setCurrentAnswers(answers) {
      window.localStorage.setItem(this.localStorageKey+'.answers', JSON.stringify(answers));
    },
  }
};
</script>