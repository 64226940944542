export default {
    computed: {
        orderedSocialNetworks() {
            let requiredOrder = ['Facebook', 'Twitter', 'Instagram'];
            let socialNetworks = this.participant.social_networks;
            let orderedSocialNetworks = socialNetworks?.sort((a, b) => {
              const indexA = requiredOrder.indexOf(a.type);
              const indexB = requiredOrder.indexOf(b.type);
              return indexA - indexB;
            });
            return orderedSocialNetworks;
        }
    }
  }